/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from "react";
import LeftArrow from "../SlideShow/LeftArrow";
import RightArrow from "../SlideShow/RightArrow";
import "./style.css";

const Carousel = (props) => {
  const { children, show, infiniteLoop, carouselCardCount, isDirectionRTL } =
    props;

  const [currentIndex, setCurrentIndex] = useState(0);

  const [touchPosition, setTouchPosition] = useState(null);
  const [length, setLength] = useState(children.length);

  const [isRepeating, setIsRepeating] = useState(
    infiniteLoop && children.length > show
  );
  const [transitionEnabled, setTransitionEnabled] = useState(true);

  const interval = useRef();

  // Set the length to match current children from props
  const autoScroll = () => {
    clearInterval(interval.current);
    interval.current = setInterval(() => {
      next();
    }, 3000);
  };

  useEffect(() => {
    autoScroll();
    setLength(children.length);
    setIsRepeating(infiniteLoop);
    return () => {
      clearInterval(interval.current);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isRepeating) {
      if (currentIndex === show - 1 || currentIndex === length - 1) {
        setTransitionEnabled(true);
      }
    }
  }, [currentIndex, isRepeating, show, length]);

  const next = () => {
    if (isRepeating || currentIndex <= length - show) {
      setCurrentIndex((prevState) => (prevState + 1) % length);
    }
  };
  const handleClickRight = () => {
    autoScroll();
    next();
  };
  const handleClickLeft = () => {
    autoScroll();
    prev();
  };
  const prev = () => {
    if (isRepeating || currentIndex > 0) {
      setCurrentIndex((prevState) =>
        prevState === 0 ? length - 1 : Math.abs((prevState % length) - 1)
      );
    }
  };
  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      next();
    }

    if (diff < -5) {
      prev();
    }

    setTouchPosition(null);
  };
  const selectSlide = (index) => {
    autoScroll();
    setCurrentIndex(index);
  };
  const handleTransitionEnd = () => {
    if (isRepeating) {
      if (currentIndex === 0) {
        setTransitionEnabled(false);
        // setCurrentIndex(length - 1);
      } else if (currentIndex === length + show) {
        setTransitionEnabled(false);
        setCurrentIndex(show);
      }
    }
  };

  const renderExtraPrev = () => {
    let output = [];
    for (let index = 0; index < show; index++) {
      output.push(children[length - 1 - index]);
    }
    output.reverse();
    return output;
  };

  const renderExtraNext = () => {
    let output = [];
    for (let index = 0; index < show; index++) {
      output.push(children[index]);
    }
    return output;
  };

  return (
    <div className="carousel-container">
      <div className="carousel-wrapper">
        <div
          className="carousel-content-wrapper"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          <div
            className={`carousel-content show-${show}`}
            style={{
              transform: `translateX(calc(-${currentIndex * (100 / show)}% - ${
                carouselCardCount === 1 ? "0px" : "30px"
              }))`,
              transition: !transitionEnabled ? "none" : undefined,
            }}
            onTransitionEnd={() => handleTransitionEnd()}
          >
            {isRepeating && renderExtraPrev()}
            {children}
            {isRepeating && renderExtraNext()}
          </div>
        </div>
      </div>
      <div className="dots-container">
        {isDirectionRTL ? (
          <RightArrow className="left-arrow-svg" onClick={handleClickLeft} />
        ) : (
          <LeftArrow className="left-arrow-svg" onClick={handleClickLeft} />
        )}
        {children.map((img, index) => (
          <div
            key={index}
            onClick={() => selectSlide(index)}
            className={index === currentIndex ? "selected-dot" : "dots"}
          />
        ))}
        {isDirectionRTL ? (
          <LeftArrow className="right-arrow-svg" onClick={handleClickRight} />
        ) : (
          <RightArrow className="right-arrow-svg" onClick={handleClickRight} />
        )}
      </div>
    </div>
  );
};

export default Carousel;
