const getUserOperatingSystem = () => {
  if (typeof window !== "undefined") {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod|Mac/.test(userAgent) && !window.MSStream) {
      return "IOS";
    }

    return "unknown";
  }
};

export default getUserOperatingSystem;
