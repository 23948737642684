import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import Carousel from "../../components/Carousel";
import EazyHeader from "../../components/Header";
import Layout from "../../components/Layout";
import WithProviders from "../../components/WithProviders";
import BannerImage from "../../images/bannerImg-min.png";
import CustomerApp from "../../images/customerApp.png";
import DeliveryApp from "../../images/deliveryApp.png";
import PickerApp from "../../images/pickerApp.png";
import ZoppingApp from "../../images/zoppingApp.png";
import LifeOneImage from "../../images/ZoppingOne.png";
import LifeThreeImage from "../../images/ZoppingThree.png";
import LifeTwoImage from "../../images/ZoppingTwo.png";
import { Message } from "../../lib/getMessages";
import "./style.css";
import getUserOperatingSystem from "../../utils/getUserOperationSystem";

const AboutUs = (props) => {
  const { language, updateLanguage, direction } = props;
  const [carouselCardCount, setCarouselCardCount] = useState(3);
  const adminAppUrl =
    getUserOperatingSystem() === "IOS"
      ? "https://apps.apple.com/in/app/zopping/id1586937529"
      : "https://play.google.com/store/apps/details?id=com.zopping.admin";

  useEffect(() => {
    if (typeof window !== "undefined" && window.innerWidth <= 640) {
      setCarouselCardCount(1);
    }
  }, []);
  const isDirectionRTL = direction === "rtl";
  return (
    <Layout
      language={language}
      isDirectionRTL={isDirectionRTL}
      location={props.location}
    >
      <div className="eazy-page">
        <Helmet title="About Us - Zopping" />
        <EazyHeader
          language={language}
          updateLanguage={updateLanguage}
          current=""
          isDirectionRTL={isDirectionRTL}
        />
        <div className="about-us-container">
          <section
            className={`banner-text-container ${
              isDirectionRTL && "rtl-support"
            }`}
          >
            <img src={BannerImage} alt="banner-img" />
            <div className="intro-text-container">
              <div
                className={`intro-left-text ${isDirectionRTL && "rtl-support"}`}
              >
                <Message dictKey="make.technology.accessible" />
              </div>
              <div className="intro-right-text">
                <p>
                  <Message dictKey="no.code.online-store" />
                </p>
                <p>
                  <Message dictKey="customizable.platform.intuitive" />
                </p>
              </div>
            </div>
          </section>
          <section className="stats-container">
            <div className="stats-wrap">
              <div className="store-owners stats">
                <h3 className="stats-number">1000+</h3>
                <p className="stats-desc">
                  <Message dictKey="happy.store.owners" />
                </p>
              </div>
              <div className="countries-data stats">
                <h3 className="stats-number">50+</h3>
                <p className="stats-desc">
                  <Message dictKey="countries.text" />
                </p>
              </div>
              <div className="online-sales stats">
                <h3 className="stats-number">$2M+</h3>
                <p className="stats-desc">
                  <Message dictKey="online.sales.text" />
                </p>
              </div>
            </div>
          </section>
          <section className="apps-container">
            <div className="apps-text-wrp">
              <div className="text-wrp">
                <h3 className="suite-apps-text">
                  <Message dictKey="suite.mobile.apps" />
                </h3>
                <hr className="desktop-border" />
                <hr className="mobile-border" />
              </div>
              <div className="apps-wrp">
                <div
                  className={`apps-text-card ${
                    isDirectionRTL && "rtl-support"
                  }`}
                >
                  <a
                    href={adminAppUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={ZoppingApp} alt="zopping-app" />
                    <p className="text-desc-one">
                      <Message dictKey="for.store.owners" />
                    </p>
                    <p className="text-desc-two">
                      <Message dictKey="zopping.app.text" />
                    </p>
                  </a>
                </div>
                <div
                  className={`apps-text-card ${
                    isDirectionRTL && "rtl-support"
                  }`}
                >
                  <a
                    href="https://play.google.com/store/apps/details?id=com.zopping.picking"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={PickerApp} alt="picker-app" />
                    <p className="text-desc-one">
                      <Message dictKey="for.store.staff" />
                    </p>
                    <p className="text-desc-two">
                      <Message dictKey="picker.app.text" />
                    </p>
                  </a>
                </div>
                <div
                  className={`apps-text-card ${
                    isDirectionRTL && "rtl-support"
                  }`}
                >
                  <a
                    href="https://play.google.com/store/apps/details?id=com.zopping.logistics"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={DeliveryApp} alt="delivery-app" />
                    <p className="text-desc-one">
                      <Message dictKey="for.delivery.agents" />
                    </p>
                    <p className="text-desc-two">
                      <Message dictKey="delivery.app.text" />
                    </p>
                  </a>
                </div>
                <div
                  className={`apps-text-card ${
                    isDirectionRTL && "rtl-support"
                  }`}
                >
                  <img src={CustomerApp} alt="customer-app" />
                  <p className="text-desc-one">
                    <Message dictKey="for.customers.text" />
                  </p>
                  <p className="text-desc-two">
                    <Message dictKey="your.app.text" />
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="carousel-container">
            <div className="slider-text-wrp">
              <div className="life-text-wrp">
                <h3>
                  <Message dictKey="life.at.zopping" />
                </h3>
                <p>
                  <Message dictKey="life.zopping.description" />
                </p>
                <hr className="desktop-border" />
                <hr className="mobile-border" />
              </div>
              <div className="slider-wrp">
                <Carousel
                  show={carouselCardCount}
                  carouselCardCount={carouselCardCount}
                  infiniteLoop={true}
                  isDirectionRTL={isDirectionRTL}
                >
                  <div className="slider-img">
                    <img src={LifeOneImage} alt="life-one" />
                  </div>
                  <div className="slider-img">
                    <img src={LifeTwoImage} alt="life-two" />
                  </div>
                  <div className="slider-img">
                    <img src={LifeThreeImage} alt="life-three" />
                  </div>
                </Carousel>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default WithProviders(AboutUs);
